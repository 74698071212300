import React from "react"
import { ThemeProvider } from "styled-components"
import LayoutDefault from "../layouts/default"

const NotFoundPage = () => (
  <ThemeProvider theme={{ mode: "light" }}>
    <LayoutDefault>
      <h1>404</h1>
    </LayoutDefault>
  </ThemeProvider>
)

export default NotFoundPage
